import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Flex, FlexContainer, GraphLine, MetaDecorator, Panel, Skeleton, Overlay, Icon } from "../../components";
import { Connect } from "../../patterns";
import { QRCodeSVG } from 'qrcode.react';
import copy from 'copy-to-clipboard';

import styles from "./Pool.module.scss";
import classnames from "classnames/bind";
import { Header } from "../../layouts";
import { useLocation, useParams, Link } from "react-router-dom";
import { poolSelector } from "../../store";
import { useRecoilValue, useRecoilState } from "recoil";
import { poolsAtom, poolsLoadingStateAtom } from "../../store";
import { shorteningAddress } from "../../utils";
import { CardBasic } from "../../components/Card";
import { address, TPool } from "../../types";
import DebotClient from "../../client/client";
import * as moment from 'moment';
import { Buffer } from "buffer";

const cnb = classnames.bind(styles);

const net: string = process.env.REACT_APP_NET ? process.env.REACT_APP_NET : 'net.ton.dev';
const netAlias: string = process.env.REACT_APP_NET_ALIAS ? process.env.REACT_APP_NET_ALIAS : 'devnet';

export const Pool = () => {
  const { id } = useParams<{id: string}>();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const [vestingList, setVestingList] = useRecoilState(poolsAtom);
  const [poolsLoadingState, setPoolsLoadingState] = useRecoilState(poolsLoadingStateAtom);
  
  const pool = useRecoilValue(poolSelector({poolIndex: id || "0"}));
  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];
  
  const buildClaimManifest = (
    pool: address
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5",
    "init": {
      "method": "invokeClaim",
      "params": {
        "pool": pool
      }
    },
    "quiet": false,
    "chain": []
  });

  const updatePools = () => {
    setPoolsLoadingState("loading");
    DebotClient.getPools().then((value) => {
      setVestingList(value.pools);
      setPoolsLoadingState("success");
    })
  }

  useEffect(() => {
    if (!vestingList.length) {
      setPoolsLoadingState("loading");
      DebotClient.getPools().then((value) => {
        setVestingList(value.pools);
        setPoolsLoadingState("success");
      })
    }
  }, [])


  return (<>
    <Header location={location || "main"} route="stage"/>  
    <main>
    <MetaDecorator
      title="Everscale vesting · Allocation with Ever Surf"
      description="Send and recieve tokens in equal shares."
      keywords="ever, surf, vesting, cliff, allocation, everscale, token, tokens, coin, coins, blockchain"
    />
    <Container fluid className={cnb("content-container")}>
      <h1 className={cnb("title", "title-large")}>{poolsLoadingState === "success" && !pool ? "Oops! Empty Pool" :<>Vesting &nbsp;&nbsp;·&nbsp;&nbsp; {pool ? <>#{Number(BigInt(pool.poolId))} {shorteningAddress(pool?.addr, true)}</> : <Skeleton/>} </>}</h1>

      {poolsLoadingState === "success" && !pool 
        ? <CardBasic
        className={cnb("stage-panel")}
        subtitle={"Sorry, there are no tokens here. The vesting pool has already been received, or the link may be incorrect."}
      />
        : <>
            <CardBasic
              className={cnb("stage-panel")}
              subtitle={"Allocation"}
              title={pool?.totalAmount ? <>{Number(Number((BigInt(pool?.totalAmount) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;Ē</> : <Skeleton/>}
              description={pool?.unlockedAmount && pool?.totalAmount ? <GraphLine amount={pool?.remainingAmount && pool?.totalAmount ? Number(Number((BigInt(pool?.remainingAmount) * BigInt(100)) / BigInt(pool?.totalAmount)) / 100) : 0} color="positive" /> : <Skeleton/>}
            />

            <CardBasic
              className={cnb("stage-panel")}
              subtitle={"Available"}
              title={pool?.unlockedAmount ? <>{Number(Number((BigInt(pool?.unlockedAmount) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;Ē</> : <Skeleton/>}
              description={pool?.cliffEnd ? moment.unix(pool?.cliffEnd).format('DD MMM, HH:mm UTC') : <Skeleton/>}
              action={<Button
                size="small"
                disabled={!Boolean(pool) || !Boolean(Number(BigInt(pool?.unlockedAmount || 0)))}
                color="primary"
                onClick={() => setConfirmed(true)}
                style={{minWidth: "72px"}}
              >Claim</Button>}
            />

            <Panel
              className={cnb("stage-panel")}
              collapse={true}
              header={<div className={cnb("title", "title-normal")}>Balances</div>}
            >
              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Locked</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.remainingAmount && pool?.unlockedAmount ? <>{Number(Number(((BigInt(pool?.remainingAmount) - BigInt(pool?.unlockedAmount)) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</> : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Withdrawn</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.remainingAmount && pool?.totalAmount ? <>{Number(Number(((BigInt(pool?.totalAmount) - BigInt(pool?.remainingAmount)) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</> : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Available</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.unlockedAmount ? <>{Number(Number((BigInt(pool?.unlockedAmount) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</> : <Skeleton/>}</Flex>
              </FlexContainer>
            </Panel>

            <Panel
              className={cnb("stage-panel")}
              collapse={true}
              header={<div className={cnb("title", "title-normal")}>Dates</div>}
              >
              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Created</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.createdAt ? moment.unix(pool?.createdAt).format('DD MMM, YYYY') : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Start</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.cliffEnd ? moment.unix(pool?.cliffEnd).format('DD MMM, YYYY') : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>End</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.vestingEnd ? moment.unix(pool?.vestingEnd).format('DD MMM, YYYY, HH:mm UTC') : <Skeleton/>}</Flex>
              </FlexContainer>
            </Panel>

            <Panel
              className={cnb("stage-panel")}
              collapse={true}
              header={<div className={cnb("title", "title-normal")}>Addresses</div>}
              >
              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>From</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.poolCreator ? pool?.poolCreator : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>To</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.recipient ? pool?.recipient : <Skeleton/>}</Flex>
              </FlexContainer>

              <FlexContainer
                className={cnb("stage-panel-row")}
                direction="column"
                justify="flex-start"
                align="stretch"
              >
                <Flex className={cnb("paragraph", "paragraph-small")}>Contract</Flex>
                <Flex className={cnb("paragraph", "paragraph-normal")}>{pool?.addr ? pool?.addr : <Skeleton/>}</Flex>
              </FlexContainer>
            </Panel>
      </>}
    </Container>
    </main>
    <Overlay
      show={confirmed}
      onHide={() => setConfirmed(false)}
      className={cnb("action-confirmed")}
      header={<></>}
      closeButton={<Button
        color="default"
        size="large"
        disabled={false}
        onClick={() => {
          updatePools();
          setConfirmed(false)
        }}
        iconLeft={{
          icon: <Icon icon="close"/>,
          animation: "none"
        }}
      >Close</Button>}
    >
      <Container fluid className={cnb("content-container", "content-container-center")}>
        <h3 className={cnb("title", "title-large")}>Confirm action</h3>
        <div className={cnb("qrcode")}>
          <QRCodeSVG
              value={pool?.addr ? `https://uri.ever.surf/debot/${"0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5"}?manifest=${Buffer.from(buildClaimManifest(pool.addr)).toString("base64").replaceAll('/+', '_-').replaceAll('=', '')}&net=${"mainnet"}` : `https://uri.ever.surf/debot/${"0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5"}`}
              bgColor="transparent"
              fgColor="#20262A"
            />
        </div>
        <div className={cnb("qrcode-desc", "color-faded", "paragraph", "paragraph-small", "align-center")}>{`Scan this QR code or follow the everlink\nto make a safe action`}</div>

      <a
        target={"_blank"}
        rel="noopener noreferrer nofollow"
        href={pool?.addr ? `https://uri.ever.surf/debot/${"0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5"}?manifest=${Buffer.from(buildClaimManifest(pool.addr)).toString("base64").replaceAll('/+', '_-').replaceAll('=', '')}&net=${"mainnet"}` : `https://uri.ever.surf/debot/${"0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5"}`}>
        <Button
          style={{width: "100%"}}
          size="large"
          color="transparent"
          onClick={() => {
            // setCopied(true);
            // setTimeout(() => {
            //   setCopied(false);
            // }, 1000);
            // copy('Vesting Surf', {
            //   debug: true,
            //   message: 'Press #{key} to copy',
            // })}
          }}
        >{copied ? "Link copied!" : "Confirm with Everlink"}</Button>
      </a>

      <Button
        style={{width: "100%"}}
        size="large"
        color="secondary"
        onClick={() => {
          updatePools();
          setConfirmed(false)
        }}
      >Done</Button>
      </Container>
    </Overlay>
  </>
  );
};

export default Pool;
