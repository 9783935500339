import callback from "./abi";

// import * as browser from "debot-browser";

import { 
  Debot,
  TPool,
  address
 } from "../types";
import { Console } from "console";
// @ts-ignore
// import * as browser from "debot-browser"; 
const browser = import("debot-browser");

export const Debots:Map<string, Debot> = new Map([
  ['vesting', {
    title: 'vesting',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5'
  }]
]);

const logger = console;

export default class DebotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DebotClient.convert('hex', 'utf8');
  static utf8ToHex = DebotClient.convert('utf8', 'hex');

  private static browserHandle: Promise<BigInt>;

  constructor () {
    DebotClient.init();
  }
  
  static async init () {
    DebotClient.browserHandle = (await browser).create_browser(Debots.get('vesting')!.name, Debots.get('vesting')!.address);
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  private static buildManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('vesting')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abi}
    }`);
  };


  /**
   * Get trading pairs with trading metadata
   **/
   static async getPools(): Promise<{pools: TPool[]}> {
    logger.log(`Calling getPools...\n`);
    const manifest = DebotClient.buildManifest("invokeGetPools", "{}");
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }

  /**
   * Get trading pairs with trading metadata
   **/
  static async getPool(pool: address): Promise<{pool: TPool}> {
    logger.log(`Calling getPool...\n`);
    //const manifest = DebotClient.buildManifest("invokeGetPairs", `{"tokenRoot": "${tokenRoot}"}`);
    const manifest = DebotClient.buildManifest("invokePoolGet", `{"pool": "${pool}"}`);
    return await (await browser).run_browser((await DebotClient.browserHandle), manifest);
  }
 
}

(async () => {
  new DebotClient();
})()

