export const abi = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
			"name": "onCreatePool",
			"inputs": [
				{"name":"status","type":"bool"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetPools",
			"inputs": [
				{"components":[{"name":"addr","type":"address"},{"name":"poolId","type":"uint256"},{"name":"poolCreator","type":"address"},{"name":"createdAt","type":"uint32"},{"name":"recipient","type":"address"},{"name":"cliffEnd","type":"uint32"},{"name":"vestingEnd","type":"uint32"},{"name":"totalAmount","type":"uint128"},{"name":"remainingAmount","type":"uint128"},{"name":"unlockedAmount","type":"uint128"}],"name":"pools","type":"tuple[]"}
			],
			"outputs": [
			]
		},
		{
			"name": "onPoolInfo",
			"inputs": [
				{"components":[{"name":"addr","type":"address"},{"name":"poolId","type":"uint256"},{"name":"poolCreator","type":"address"},{"name":"createdAt","type":"uint32"},{"name":"recipient","type":"address"},{"name":"cliffEnd","type":"uint32"},{"name":"vestingEnd","type":"uint32"},{"name":"totalAmount","type":"uint128"},{"name":"remainingAmount","type":"uint128"},{"name":"unlockedAmount","type":"uint128"}],"name":"pool","type":"tuple"}
			],
			"outputs": [
			]
		}
	]
}`;

export default {
    abi
}