import { useState } from "react";
import styles from "./Panel.module.scss";
import classnames from "classnames/bind";
import { Icon } from "../Icon";

const cn = classnames.bind(styles);

export const Panel = ({ className = "", type = "content", collapse, children, header}: {
  className?: string,
  type?: string,
  collapse?: boolean,
  children?: React.ReactNode,
  header?: React.ReactNode
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(Boolean(collapse));
  return <div className={cn("panel", className, (type ? "panel-" + type : undefined))}>
    {header && <div className={cn("panel-header")} onClick={() => collapse !== undefined ? setCollapsed(!collapsed) : null}>
      {header} {collapse !== undefined && (collapsed ? <Icon icon="chevron-down"/> : <Icon icon="chevron-up"/>)}
    </div>}
    <div className={cn("panel-body", {"panel-body-collapse": collapse !== undefined}, {"panel-body-collapse-closed": collapsed})}>
      {children}
    </div>
  </div>;
};

export default Panel;
