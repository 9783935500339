import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Button, Flex, FlexContainer, MetaDecorator, Overlay, Panel, Skeleton, Icon } from "../../components";
import { Connect } from "../../patterns";
import { QRCodeSVG } from 'qrcode.react';
import copy from 'copy-to-clipboard';

import styles from "./Claim.module.scss";
import classnames from "classnames/bind";
import { Header } from "../../layouts";
import { useLocation, useParams, Link } from "react-router-dom";
import { poolsAtom, poolSelector } from "../../store";
import { useRecoilState, useRecoilValue } from "recoil";
import { shorteningAddress } from "../../utils";
import { address, TPool } from "../../types";
import DebotClient from "../../client/client";

const cnb = classnames.bind(styles);

export const Claim = () => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const { id } = useParams<{id: string}>();
  const [poolResponse, setPoolResponse] = useState<TPool>();
  // const [pool, setPool] = useState<TPool>();
  const [vestingList, setVestingList] = useRecoilState(poolsAtom);
  
  const pool = useRecoilValue(poolSelector({poolIndex: id || "0"}));
  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];

  const buildClaimManifest = (
    pool: address
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:426046f5786e2a3748c975ae2c0245bb7ca970f8ac9845175bd2baa549a8e4d5",
    "initMethod": "invokeClaim",
    "initArgs": {
        "pool": pool
    },
    "abi": {
        "ABI version": 2,
        "version": "2.2",
        "header": ["time"],
        "functions": [
            {
                "name": "onClaim",
                "inputs": [
                    {"name":"status","type":"uint8"}
                ],
                "outputs": [
                ]
            }
        ]
    },
    "quiet": false,
    "chain": []
  });


  useEffect(() => {
    if (!vestingList.length)
      DebotClient.getPools().then((value) => {
        setVestingList(value.pools);
      })
  }, [])

    return (<>
      <Header location={location || "main"} id={id} route="claim" />  
      <main>
      <MetaDecorator
        title="Everscale vesting · Allocation with Ever Surf"
        description="Send and recieve tokens in equal shares."
        keywords="ever, surf, vesting, cliff, allocation, everscale, token, tokens, coin, coins, blockchain"
      />
      <Container fluid className={cnb("content-container")}>
        <Connect/>
        <h1 className={cnb("title", "title-large")}>Claim &nbsp;&nbsp;·&nbsp;&nbsp;  {pool ? <>#{Number(BigInt(pool.poolId))} {shorteningAddress(pool?.addr, true)}</> : <Skeleton/>} </h1>

        <Panel
          className={cnb("stage-panel")}
          header={<div className={cnb("title", "title-normal")}>Estimated balance changes</div>}
          >
          <FlexContainer
            className={cnb("stage-panel-row")}
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex className={cnb("paragraph", "paragraph-normal")}>Withdraw</Flex>
            <Flex className={cnb("paragraph", "paragraph-normal")}>{(20000).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</Flex>
          </FlexContainer>

          <FlexContainer
            className={cnb("stage-panel-row")}
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex className={cnb("paragraph", "paragraph-normal")}>Fee</Flex>
            <Flex className={cnb("paragraph", "paragraph-normal")}>up to {(50.51).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</Flex>
          </FlexContainer>
          <FlexContainer
            className={cnb("stage-panel-row")}
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex className={cnb("paragraph", "paragraph-normal")}>Network </Flex>
            <Flex className={cnb("paragraph", "paragraph-normal")}>~ {(0.01).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</Flex>
          </FlexContainer>
        </Panel>

        {/* <Panel
          className={cnb("stage-panel")}
          collapse={true}
          header={<div className={cnb("title", "title-normal")}>Fees details</div>}
        >

          <FlexContainer
            className={cnb("stage-panel-row")}
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex className={cnb("paragraph", "paragraph-normal")}>Network </Flex>
            <Flex className={cnb("paragraph", "paragraph-normal")}>~ {(0.01).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;EVER</Flex>
          </FlexContainer>

        </Panel> */}
        <Button
          style={{width: "100%"}}
          size="large"
          color="primary"
          onClick={() => setConfirmed(true)}
        >Proceed to claim</Button>
      </Container>
      </main>
      <Overlay
        show={confirmed}
        onHide={() => setConfirmed(false)}
        className={cnb("action-confirmed")}
        header={<></>}
        closeButton={<Button
          color="default"
          size="large"
          disabled={false}
          onClick={() => setConfirmed(false)}
          iconLeft={{
            icon: <Icon icon="close"/>,
            animation: "none"
          }}
        >Close</Button>}
      >
        <Container fluid className={cnb("content-container", "content-container-center")}>
          <h3 className={cnb("title", "title-large")}>Confirm action</h3>
          <div className={cnb("qrcode")}>
            <QRCodeSVG
              value={`Hello you blastard!`}
              bgColor="transparent"
              fgColor="#20262A"
            />
          </div>
          <div className={cnb("qrcode-desc", "color-faded", "paragraph", "paragraph-small", "align-center")}>{`Scan this QR code or follow the everlink\nto make a safe action`}</div>

        <Button
          style={{width: "100%"}}
          size="large"
          color="transparent"
          onClick={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 1000);
            copy('Vesting Surf', {
              debug: true,
              message: 'Press #{key} to copy',
            })}
          }
        >{copied ? "Link copied!" : "Confirm with Everlink"}</Button>

        <Button
          style={{width: "100%"}}
          size="large"
          color="secondary"
          onClick={() => setConfirmed(false)}
        >Done</Button>
        </Container>
      </Overlay>
    </>
    );
  };

export default Claim;
