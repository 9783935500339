import { useState, useRef, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Link, matchPath } from "react-router-dom";
import styles from "./Header.module.scss";
import classnames from "classnames/bind";
import useOutsideClick from "../../utils/outsideClickCallback";
import { Button, Icon } from "../../components";

const cnb = classnames.bind(styles);

export const Header = ({location, route = "main", id, ...props}: {location: string, route?: "main" | "stage" | "claim", id?: string}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [animated, setAnimated] = useState<boolean>(false);
  const refPicker = useRef<HTMLDivElement>(null);

  useOutsideClick(refPicker, () => {});

  // const animateBack = (e: any) => {
  //   if (expanded) {
  //     setAnimated(true);
  //     setTimeout(() => setAnimated(false), 300);
  //   }
  //   e.stopPropagation();
  //   setExpanded(expanded ? false : true);
  // }

  // useEffect(() => {
  //   document.addEventListener('click', () => {
  //     if (expanded) {
  //       setAnimated(true);
  //       setTimeout(() => setAnimated(false), 300);
  //     }
  //     setExpanded(false);
  //   });
  //   return () => {
  //     document.removeEventListener('click', () => {
  //       if (expanded) {
  //         setAnimated(true);
  //         setTimeout(() => setAnimated(false), 300);
  //       }
  //       setExpanded(false);
  //     });
  //   }
  // }, [])

  return (<>
    <header className={cnb("header")}>
      <Navbar
        className={cnb("navbar")}
      >
        <Nav className={cnb("navbar-nav", "me-auto")}>
          {(() => {switch (route) {
            case "main":
              return <><Navbar.Brand className={cnb("navbar-brand")}><Link to="/" className="logo"><span>Vesting <Logo/> Surf</span></Link></Navbar.Brand> <span className={cnb("everscale", "paragraph paragraph-small")}>from <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.14286 1L1 4.14286H5.85714V9L9 5.85714L9 1H4.14286Z" fill="#676B6E" stroke="#676B6E" strokeLinejoin="round"/></svg> Everscale founders</span></>

            case "stage":
              return <Link to="/"><Button
              size="large"
              color="default"
              iconLeft={{
                icon: <Icon icon="arrow-left" />,
                animation: "none"
              }}
            ></Button></Link>

            case "claim":
              return <Link to={"/stage/" + id}><Button
              size="large"
              color="default"
              iconLeft={{
                icon: <Icon icon="close" />,
                animation: "none"
              }}
            >Cancel</Button></Link>
          
            default:
              return <><Navbar.Brand className={cnb("navbar-brand")}><Link to="/" className="logo"><span>Vesting <Logo/> Surf</span></Link></Navbar.Brand> <span className={cnb("everscale", "paragraph paragraph-small")}>from <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.14286 1L1 4.14286H5.85714V9L9 5.85714L9 1H4.14286Z" fill="#676B6E" stroke="#676B6E" strokeLinejoin="round"/></svg> Everscale founders</span></>
          }})()}
        </Nav>
      </Navbar>
    </header>
    </>
  );
};

export default Header;
