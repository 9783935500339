import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";

import "./assets/styles/index.scss";

import { Main, Content, Pool, Claim } from "./views";
import { Header, Footer } from "./layouts";
import { Connect } from "./patterns";

const App = () => {

  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];
    return (
      <div className={cn("ws-app", location, {"isMobile": isMobile, "main": !location})}>
        <main>
          <Connect disabled={true}/>
          <Routes>
            <Route path="/legal-notes/:id" element={<Content/>} />
            <Route path="/route" element={<></>} />
            <Route path="/" element={<Main/>} />
            <Route path="/pool/:id" element={<Pool/>} />
            <Route path="/pool/:id/claim" element={<Claim/>} />
            <Route path="*" element={<Navigate to="/"/> }/>
          </Routes>
        </main>
        <Footer />
      </div>
    )
};

export default App;
