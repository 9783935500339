import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator, CardBasic, Skeleton, GraphLine } from "../../components";
import { Connect } from "../../patterns";

import Utils from "../../client/utils";

import styles from "./Main.module.scss";
import classnames from "classnames/bind";
import { VestingItem } from "../../components/VestingItem";
import { Header } from "../../layouts";
import { useLocation, Link } from "react-router-dom";

import { useRecoilState } from "recoil";
import { poolsAtom, poolsLoadingStateAtom } from "../../store";
import DebotClient from "../../client/client";
import { shorteningAddress } from "../../utils";

const cnb = classnames.bind(styles);

// const Visual = () => {
//   const Layer = () => <svg width="513" height="513" viewBox="0 0 513 513" fill="#506DFD" xmlns="http://www.w3.org/2000/svg">
//     <path d="M0.0387726 153.64V0.0391846L256.04 256.04L0.0387726 512.041V358.44V256.04V153.64Z"/>
//     <path d="M256.04 153.64V0.0391846L512.041 256.04L256.04 512.041V358.44V256.04V153.64Z"/>
//   </svg>;

//   const ref = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     document.addEventListener("mousemove", e => {
//       gsap.to(".visual svg:not(:first-child)", {
//         rotate: (index, target, targets) => {
//           return - (1600 + e.clientX) * (index + 1) / 600;
//         },
//         scale: (index, target, targets) => {
//           return 1 - (1 - e.clientY/window.screen.height) * 0.2 * (1 - ((14 - index) + 1) / 15);
//         },
//       })
//     });
  
//     return () => {
//       gsap.killTweensOf(".visual svg");
//       if (ref.current)
//         ref.current.outerHTML = ref.current.outerHTML;
//     }
//   }, [])
  
  
//   return (<div ref={ref} className="visual">
//     {[...Array(15)].map((el, index) => <Layer key={index} />)}
//   </div>);
// }

const PoolItem = ({ className = "", total, remaining, alignRight, action, id, address}: {
  className?: string,
  total?: bigint,
  remaining?: bigint,
  alignRight?: "flex-start" | "flex-end" | "center",
  id?: string | number,
  address?: string,
  action?: boolean
}) => {
  // const [collapsed, setCollapsed] = useState<boolean>(Boolean(collapse));
  return <CardBasic
    className={className}
    alignRight={alignRight}
    subtitle={null}
    title={total ? <>{Number(Number((BigInt(total) * BigInt(100)) / BigInt(10**9) as bigint) / 100).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 4})}&thinsp;Ē</> : <Skeleton className={cnb("skeleton-up")}/>}
    description={address && id ? <>#{id}<span className={cnb("address","color-tertiary")}>{shorteningAddress(address, true)}</span></> : <Skeleton wide="triplet" className={cnb("skeleton-up-alt")}/>}
    action={address ? <div className={cnb("paragraph", "paragraph-large")}><GraphLine color="positive" amount={remaining && total ? Number(Number((BigInt(remaining) * BigInt(100)) / BigInt(total)) / 100) : 0}/></div> : <Skeleton className={cnb("skeleton-down")}/>}
  />;
};


export const Main = () => {
  const [vestingList, setVestingList] = useRecoilState(poolsAtom);
  const [poolsLoadingState, setPoolsLoadingState] = useRecoilState(poolsLoadingStateAtom);
  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];

  useEffect(() => {
    setPoolsLoadingState("loading");
    DebotClient.getPools().then((value) => {
      setVestingList(value.pools);
      setPoolsLoadingState("success");
    })
  }, [])
  
  return (<>
    <Header location={location || "main"} />  
    <main>
    <MetaDecorator
      title="Everscale vesting · Allocation with Ever Surf"
      description="Send and recieve tokens in equal shares."
      keywords="ever, surf, vesting, cliff, allocation, everscale, token, tokens, coin, coins, blockchain"
    />
    <Container fluid className={cnb("content-container")}>
      <h1 className={cnb("title", "title-large")}>Everscale vesting</h1>
      <>{console.log(vestingList)}</>
      {vestingList && vestingList.length ? vestingList.map((item) => item).sort((a, b) => parseInt(b.poolId) - parseInt(a.poolId)).map((item, index) => <Link to={"/pool/"+item.addr} key={index}><PoolItem
        className={cnb("vesting-item")}
        alignRight={"flex-start"}
        total={item.totalAmount}
        remaining={BigInt(item.remainingAmount)}
        id={Number(BigInt(item.poolId)).toString()}
        address={item.addr}
        /></Link>)
      : [...Array(3)].map((el, index) => <PoolItem
          key={index}
          className={cnb("vesting-item")}
          alignRight={"flex-start"}
          total={undefined}
          remaining={undefined}
          id={undefined}
          address={undefined}
      />)}
    </Container>
    </main>
  </>
  );
};

export default Main;
