import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import classnames from "classnames/bind";
const cnb = classnames.bind(styles);


export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container fluid className={styles.container}>
        <Nav as="ul" className={styles.nav}>
          {/* <Nav.Item as="li" className={styles['nav-item']}>
            <Link to="/legal-notes/surf-decentralization-policy" className={styles.dod}>Surf Decentralization Policy</Link>
          </Nav.Item> */}
          <Nav.Item as="li" className={`${styles['nav-item']} ${styles['copyright']} paragraph paragraph-small`}>
            {(new Date()).getFullYear().toString()} &copy; Vesting is a part of the <span><a href="https://ever.surf" target="_blank" rel="noopener noreferrer nofollow">Ever&nbsp;<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10V1.00226C7 1.00141 6.99901 1.00095 6.99836 1.00149L1 6.00012V14.9979C1 14.9987 1.00099 14.9992 1.00164 14.9986L7 10Z" fill="#4963E6" stroke="#4963E6" strokeWidth="2" strokeLinejoin="round"/></svg>&nbsp;Surf</a> ecosystem</span>
          </Nav.Item>
          {/* <Nav.Item as="li" className={`paragraph paragraph-small ${styles['nav-item']}`}>
            <a href="mailto:welcome@ever.surf">welcome@ever.surf</a>
          </Nav.Item> */}
          <Nav.Item as="li" className={`action action-small ${styles['nav-item']}`}>
            <a href="https://github.com/EverSurf/contracts/tree/main/VestingPool" target="_blank" rel="noopener noreferrer nofollow">Audited &amp; Verified by Pruvendo</a>
          </Nav.Item>
        </Nav>
      </Container>
    </footer>
  );
};

export default Footer;
