import React from 'react';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from "react-router-dom";
// import cn from "classnames";
// import { store } from "./redux/store/configure-store";
// import { Provider } from "react-redux";

import { RecoilRoot } from 'recoil';

import { createRoot } from 'react-dom/client';

import "./assets/styles/index.scss";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    <React.StrictMode>
      {/* <Provider store={store}> */}
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      {/* </Provider> */}
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


