import { atom, selectorFamily } from "recoil";
// import { recoilPersist } from "recoil-persist";
import { address, TPool } from "../types";

// const { persistAtom } = recoilPersist({ key: 'recoil' });

export const poolsAtom = atom<TPool[]>({
    key: 'Pools',
    default: []
});

// TODO use useRecoilStateLoadable here
export const poolsLoadingStateAtom = atom<string>({
    key: 'PoolsState',
    default: "init"
});


export const poolSelector = selectorFamily({
  key: 'PoolSelector',
  get: ({poolIndex}: {poolIndex: address}) => ({ get }) => {
      const pools = get(poolsAtom);
      return pools.find((pool) => pool.addr === String(poolIndex));
  }
});
